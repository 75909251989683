<template>
    <div class="view-app">
        <sidebar-view>
            <dashboard-container>
                <div class="card mb-7">
                    <div class="card-body">
                        <template v-if="pendingVerify">
                            <div class="text-center p-4">
                                <h2 class="mb-4">Check your inbox</h2>
                                <p class="lead mb-4 px-5">
                                    Click the link we sent to
                                    {{ currentEmail }} to complete your email
                                    change.
                                </p>
                                <p class="pb-1 mb-0 space-top-1">
                                    Didn't get the email?
                                </p>
                                <btn
                                    @click="retryVerify()"
                                    app
                                    color="primary"
                                    :loading="retryLoading"
                                >
                                    <i
                                        v-if="!retryLoading && retrySuccess"
                                        class="fas fa-check mr-2"
                                    ></i>
                                    Send it again
                                </btn>
                                <span
                                    v-if="retryFailure"
                                    class="d-flex justify-content-center"
                                >
                                    <p
                                        class="text-small text-danger w-lg-50 text-center"
                                        v-if="retryFailureCause"
                                    >
                                        {{ retryFailureCause }}
                                    </p>
                                    <p
                                        class="text-small text-danger w-lg-50 text-center"
                                        v-else
                                    >
                                        An unexpected error occurred. Please try
                                        again later.
                                    </p>
                                </span>
                                <span
                                    v-if="nRetries >= 5"
                                    class="d-flex justify-content-center"
                                >
                                    <p
                                        class="text-small text-danger w-lg-50 text-center"
                                    >
                                        The email has been sent too many times.
                                        If you still aren't receiving it, please
                                        try signing up with a different email.
                                    </p>
                                </span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="card-header-padded">Change Email Address</div>

                            <form class="form-horizontal" role="form">
                                <validation-observer ref="emailValidator">
                                    <div class="form-group">
                                        <p class="p-0 m-0">
                                            Your email:
                                        </p>
                                        <h4 class="m-0 p-0 mb-4">
                                            {{ currentEmail }}
                                        </h4>

                                        <label
                                            class="input-label lead custom-field-label mb-0 ml-1"
                                            >Enter your new email</label
                                        >
                                        <text-input
                                            placeholder="New email"
                                            validation-rules="email|required"
                                            v-model="email"
                                            @input="backendError = ''; init = true;"
                                            :addErrors="backendError ? [backendError] : []"
                                        />
                                    </div>
                                </validation-observer>
                                <div class="d-flex justify-content-end">
                                    <btn
                                        @click="updateEmail"
                                        :loading="updateEmailLoading"
                                        app
                                        size="sm"
                                        :disabled="!init"
                                    >
                                        Save
                                    </btn>
                                </div>
                            </form>
                        </template>
                    </div>
                </div>
                <div class="card mb-7">
                    <div class="card-body">
                        <div class="card-header-padded">Email Preferences</div>
                        <div
                            v-if="emailPreferencesLoading"
                            class="d-flex justify-content-center py-5"
                        >
                            <loading color="primary" :size="50" />
                        </div>
                        <div v-else>
                            <p class="font-weight-bold">
                                I would like Thriftax to email me about:
                            </p>
                            <validation-provider>
                                <label
                                    class="toggle-switch d-flex align-items-center mb-3"
                                    for="email_reminders_id"
                                >
                                    <input
                                        v-model="
                                            emailPreferences.email_tax_reminders
                                        "
                                        type="checkbox"
                                        @change="emailPreferencesDirty = true"
                                        class="toggle-switch-input"
                                        id="email_reminders_id"
                                    />
                                    <span class="toggle-switch-label">
                                        <span
                                            class="toggle-switch-indicator"
                                        ></span>
                                    </span>
                                    <span class="toggle-switch-content">
                                        <span class="d-block"
                                            >Tax Reminders</span
                                        >
                                    </span>
                                </label>
                            </validation-provider>
                            <label
                                class="toggle-switch d-flex align-items-center mb-3"
                                for="email_promotions_id"
                            >
                                <input
                                    v-model="emailPreferences.email_promotions"
                                    type="checkbox"
                                    @change="emailPreferencesDirty = true"
                                    class="toggle-switch-input"
                                    id="email_promotions_id"
                                />
                                <span class="toggle-switch-label">
                                    <span
                                        class="toggle-switch-indicator"
                                    ></span>
                                </span>
                                <span class="toggle-switch-content">
                                    <span class="d-block"
                                        >Personalized Promotions
                                    </span>
                                </span>
                            </label>
                            <label
                                class="toggle-switch d-flex align-items-center mb-3"
                                for="email_offers_id"
                            >
                                <input
                                    @change="emailPreferencesDirty = true"
                                    v-model="emailPreferences.email_offers"
                                    type="checkbox"
                                    class="toggle-switch-input"
                                    id="email_offers_id"
                                />
                                <span class="toggle-switch-label">
                                    <span
                                        class="toggle-switch-indicator"
                                    ></span>
                                </span>
                                <span class="toggle-switch-content">
                                    <span class="d-block"
                                        >Special Offers from Our Partners</span
                                    >
                                </span>
                            </label>
                            <div class="d-flex justify-content-end">
                                <btn
                                    @click="saveEmailPreferences"
                                    app
                                    size="sm"
                                    :disabled="!emailPreferencesDirty"
                                >
                                    Save
                                </btn>
                            </div>
                        </div>
                    </div>
                </div>
            </dashboard-container>
        </sidebar-view>
    </div>
</template>

<script>
import sidebarView from './sidebar/sidebar';
import dashboardContainer from './dashboard_container';

export default {
    data: () => ({
        currentEmail: null,
        init: false,
        email: null,
        pendingVerify: false,
        retryLoading: false,
        retrySuccess: false,
        retryFailure: false,
        retryFailureCause: null,
        nRetries: 0,
        updateEmailLoading: false,
        backendError: '',
        emailPreferences: {},
        emailPreferencesLoading: true,
        emailPreferencesDirty: false,
    }),
    methods: {
        saveEmailPreferences() {
            this.$store.getters.client
                .post('/email-preferences/', this.emailPreferences)
                .then((resp) => {
                    this.emailPreferencesDirty = false;
                });
        },
        async updateEmail() {
            var valid = await this.$refs.emailValidator.validate();
            if (!valid) {
                return;
            }
            this.updateEmailLoading = true;
            if (this.init) {
                const self = this;
                self.status = 'submitting';
                this.$store
                    .dispatch('updateUserEmail', {
                        oldEmail: this.currentEmail,
                        access: localStorage.getItem('access'),
                        refresh: localStorage.getItem('refresh'),
                        email: this.email,
                        retry: false,
                    })
                    .then(() => {
                        this.pendingVerify = true;
                        this.updateEmailLoading = false;
                        this.backendError = ``;
                    })
                    .catch((error) => {
                        if (
                            error.response.data ==
                            'This email has already been verified'
                        ) {
                            this.retryFailureCause = error.response.data;
                        }
                        if (
                            error.response.data.email &&
                            error.response.data.email[0] ==
                                'user with this email address already exists.'
                        ) {
                            this.backendError =
                                'A user with this email address already exists.';
                        }
                        this.updateEmailLoading = false;
                    });
            }
        },
        retryVerify() {
            if (this.nRetries >= 5) {
                return;
            }

            this.retryLoading = true;
            this.retrySuccess = false;
            this.retryFailure = false;
            return this.$store
                .dispatch('updateUserEmail', {
                    oldEmail: this.currentEmail,
                    access: this.access,
                    refresh: this.refresh,
                    email: this.email,
                    retry: true,
                })
                .then(() => {
                    this.retryLoading = false;
                    this.retrySuccess = true;
                    this.nRetries++;
                })
                .catch((err) => {
                    this.retryFailure = true;
                    this.retryLoading = false;
                });
        },
    },
    created() {
        this.$store.getters.client.get('/email-preferences/').then((resp) => {
            this.emailPreferences = resp.data;
            this.emailPreferencesLoading = false;
        });
        this.currentEmail = localStorage.getItem('email');
    },
    components: {
        dashboardContainer,
        sidebarView,
    },
};
</script>
